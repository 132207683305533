import './bootstrap';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import '../css/app.css';

import {createRoot} from 'react-dom/client';
import {createInertiaApp, router} from '@inertiajs/react';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {IonApp, setupIonicReact} from "@ionic/react";
import {setDefaultOptions} from "date-fns";
import {ja} from "date-fns/locale";
import TaskcomRouter from "@/Components/Router/TaskcomRouter";
import {App as CapacitorApp} from "@capacitor/app";
import * as Sentry from "@sentry/react";

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

setupIonicReact();
setDefaultOptions({locale: ja});

CapacitorApp.addListener('resume', () => {
    router.reload();
});

if (import.meta.env.PROD) {
    Sentry.init({
        dsn: "https://84e199afb6e519219f7f8ea8236d8373@o4508280994267136.ingest.us.sentry.io/4508300977242112",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob('./Pages/**/*.tsx')),
    setup({el, App, props}) {
        // if (import.meta.env.DEV) {
        createRoot(el).render(
            <IonApp>
                <TaskcomRouter>
                    <App {...props} />
                </TaskcomRouter>
            </IonApp>);
        // }

        // hydrateRoot(el, <IonApp>
        //     <TaskcomRouter>
        //         <App {...props} />
        //     </TaskcomRouter>
        // </IonApp>);
    },
    progress: {
        color: '#4B5563',
    },
});
