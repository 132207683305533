import {PropsWithChildren, useContext, useEffect, useLayoutEffect, useRef} from "react";
import {IonReactRouter} from "@ionic/react-router";
import {BrowserHistory, createBrowserHistory} from "history";
import {router} from "@inertiajs/react";
import {formatISO} from "date-fns";
import {IonRouterOutlet, RouteManagerContext} from "@ionic/react";
import {App, URLOpenListenerEvent} from "@capacitor/app";

export default function TaskcomRouter({children}: PropsWithChildren<{}>) {
    const historyRef = useRef<BrowserHistory>(createBrowserHistory());
    const context = useContext(RouteManagerContext);

    useLayoutEffect(() => {
        if (historyRef.current) {
            App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
                // Example url: https://beerswift.app/tabs/tab2
                // slug = /tabs/tab2
                console.log(event.url);
                const slug = event.url.split('taskcom.fermate.co.jp').pop();
                if (slug) {
                    router.visit(slug);
                }
                // If no match, do nothing - let regular routing
                // logic take over
            });

            const unlisten = historyRef.current.listen((location) => {
                console.log(formatISO(new Date()) + JSON.stringify(location));
                switch (location.action) {
                    case "PUSH":
                    case "REPLACE":
                        router.visit(location.location.pathname + location.location.search, {
                            replace: true,

                        });
                        break;
                    case "POP":
                        break;
                    default:
                        console.log("UNKNOWN", location.location.pathname);
                        break;
                }
            });
            return () => {
                unlisten();
                // console.log("unlisten");
            };
        }
    }, []);

    //@ts-ignore
    //TODO: ここのtsエラーを直す
    return <IonReactRouter history={historyRef.current}>
        <IonRouterOutlet>{children}</IonRouterOutlet>
    </IonReactRouter>;
};
